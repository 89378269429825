import React from "react";
import Link from "gatsby-plugin-transition-link";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

const Page = () => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Mortgage Calculators | Thistle Financial"
        description="Are you thinking of buying a home or refinancing? Plug your numbers into these handy calculators to help you with your planning."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-12 md:pt-16 pb-20 md:pb-32">
        <div className="container">
          <header className="text-center max-w-3xl mx-auto mb-10 md:mb-14">
            <h1>Mortgage Calculators</h1>
            <p>
              Are you thinking of buying a home or refinancing? Plug your
              numbers into these handy calculators to help you with your
              planning.
            </p>
          </header>

          <div
            className={`grid md:grid-cols-2 md:gap-x-10 gap-y-10 max-w-[800px] mx-auto`}
          >
            <Link
              to="/mortgage-calculator/"
              className="group flex items-center space-x-4"
            >
              <div className="w-10 h-10 rounded-full flex justify-center items-center border border-primary-400 group-hover:bg-primary-400 transition-colors duration-300 ease-linear">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8H17"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                  <path
                    d="M10 1L17 8L10 15"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                </svg>
              </div>
              <span className="text-black group-hover:text-primary-400 text-xl tracking-wide font-bold transition-colors duration-300 ease-linear">
                Mortgage Calculator
              </span>
            </Link>

            <Link
              to="/va-loan-calculator/"
              className="group flex items-center space-x-4"
            >
              <div className="w-10 h-10 rounded-full flex justify-center items-center border border-primary-400 group-hover:bg-primary-400 transition-colors duration-300 ease-linear">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8H17"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                  <path
                    d="M10 1L17 8L10 15"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                </svg>
              </div>
              <span className="text-black group-hover:text-primary-400 text-xl tracking-wide font-bold transition-colors duration-300 ease-linear">
                VA Loan Calculator
              </span>
            </Link>

            <Link
              to="/fha-loan-calculator/"
              className="group flex items-center space-x-4"
            >
              <div className="w-10 h-10 rounded-full flex justify-center items-center border border-primary-400 group-hover:bg-primary-400 transition-colors duration-300 ease-linear">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8H17"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                  <path
                    d="M10 1L17 8L10 15"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                </svg>
              </div>
              <span className="text-black group-hover:text-primary-400 text-xl tracking-wide font-bold transition-colors duration-300 ease-linear">
                FHA Loan Calculator
              </span>
            </Link>

            <Link
              to="/home-affordability-calculator/"
              className="group flex items-center space-x-4"
            >
              <div className="w-10 h-10 rounded-full flex justify-center items-center border border-primary-400 group-hover:bg-primary-400 transition-colors duration-300 ease-linear">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8H17"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                  <path
                    d="M10 1L17 8L10 15"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="stroke-current text-primary-400 group-hover:text-white transition-colors duration-300 ease-linear"
                  />
                </svg>
              </div>
              <span className="text-black group-hover:text-primary-400 text-xl tracking-wide font-bold transition-colors duration-300 ease-linear">
                Home Affordability Calculator
              </span>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
